import { defineStore } from 'pinia';
import Client from '@/../lib/plugins/axios';
import router from '@/router';
import api from '@/../lib/api';
import { useAppStore } from './useAppStore';

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      groups: {
        data: [],
        type: '',
        error: '',
      },
      authToken: localStorage.getItem('authToken') || '',
      authStatus: {
        type: '',
        error: '',
      },
      signupStatus: {
        type: '',
        error: '',
      },
      user: '',
      userStatus: {
        type: '',
        error: '',
      },
      sendEmailConfirmationStatus: {
        type: '',
        error: '',
      },
      confirmEmailStatus: {
        type: '',
        error: '',
      },
      forgotPasswordStatus: {
        type: '',
        error: '',
      },
      resetPasswordStatus: {
        type: '',
        error: '',
      },
      users: {
        data: [],
        status: '',
        error: '',
      },
      selectedUser: null,
    };
  },

  actions: {
    setToken(token) {
      localStorage.setItem('authToken', token);
      Client.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.authToken = token;
    },

    async login(payload) {
      this.authToken = '';
      this.authStatus.type = 'loading';
      this.authStatus.error = '';

      try {
        const { data } = await api.auth.login(payload);
        const userData = data.data;

        this.authStatus.type = 'success';
        this.authStatus.error = null;

        if (userData.confirmed_at === null) {
          return userData;
        } else {
          // TO REMOVE
          userData.confirmed_at = '2022.02.03';
        }

        this.setToken(userData.token);

        return userData;
      } catch (e) {
        this.authStatus.type = 'error';
        this.authStatus.error = e;
        return Promise.reject(e);
      }
    },

    async signup(payload) {
      this.signupStatus.type = 'loading';

      try {
        const { data } = await api.auth.signup(payload);
        const userData = data.data;

        this.signupStatus.type = 'success';
        this.signupStatus.error = null;

        if (userData.confirmed_at === null) {
          return userData;
        } else {
          // TO REMOVE
          userData.confirmed_at = '2022.02.03';
        }

        localStorage.setItem('authToken', userData.token);
        Client.defaults.headers.common.Authorization = `Bearer ${userData.token}`;

        this.authToken = userData.token;

        this.getUser();
      } catch (e) {
        this.signupStatus.type = 'error';
        this.signupStatus.error = e;
        return Promise.reject(e);
      }
    },

    async logout() {
      try {
        if (this.authToken) {
          await api.auth.logout();
        }
      } finally {
        this.authToken = '';
        this.authStatus.type = '';
        this.authStatus.error = '';
        this.user = '';
        this.userStatus.type = '';
        this.userStatus.error = '';

        delete Client.defaults.headers.common.Authorization;
        localStorage.removeItem('authToken');
        router.push({ name: 'home' }).catch(() => {});
      }
    },

    async getUser() {
      this.userStatus.type = 'loading';

      try {
        const { data } = await api.users.getCurrentUser();

        this.user = data.data;
        this.userStatus.type = 'success';
        this.userStatus.error = null;

        if (!data.data.social_network || !data.data.social_link) {
          const appStore = useAppStore();

          appStore.showModalSocialVisible(true);
        }
      } catch (e) {
        this.userStatus.type = 'error';
        this.userStatus.error = e;
      }
    },

    async getGroups() {
      this.groups.type = 'loading';

      try {
        const { data } = await api.users.getGroups();

        this.groups.data = data.data;
        this.groups.type = 'success';
        this.groups.error = null;
      } catch (e) {
        this.groups.type = 'error';
        this.groups.error = e;
      }
    },

    async sendEmailConfirmation(email) {
      this.sendEmailConfirmationStatus.type = 'loading';

      try {
        await api.auth.sendEmailConfirmation(email);
        this.sendEmailConfirmationStatus.type = 'success';
        this.sendEmailConfirmationStatus.error = null;
      } catch (e) {
        this.sendEmailConfirmationStatus.type = 'error';
        this.sendEmailConfirmationStatus.error = e;
        return Promise.reject(e);
      }
    },

    async confirmEmail() {
      this.confirmEmailStatus.type = 'loading';

      try {
        await api.auth.confirmEmail();
        this.confirmEmailStatus.type = 'success';
        this.confirmEmailStatus.error = null;
      } catch (e) {
        this.confirmEmailStatus.type = 'error';
        this.confirmEmailStatus.error = e;
        return Promise.reject(e);
      }
    },

    async forgotPassword(email) {
      this.forgotPasswordStatus.type = 'loading';

      try {
        await api.auth.forgotPassword(email);
        this.forgotPasswordStatus.type = 'success';
        this.forgotPasswordStatus.error = null;
      } catch (e) {
        this.forgotPasswordStatus.type = 'error';
        this.forgotPasswordStatus.error = e;
        return Promise.reject(e);
      }
    },

    async resetPassword(userData) {
      this.resetPasswordStatus.type = 'loading';

      try {
        await api.auth.resetPassword(userData);
        this.resetPasswordStatus.type = 'success';
        this.resetPasswordStatus.error = null;
      } catch (e) {
        this.resetPasswordStatus.type = 'error';
        this.resetPasswordStatus.error = e;
        return Promise.reject(e);
      }
    },

    async getUsers() {
      this.users.status = 'loading';

      try {
        const { data } = await api.users.get();

        this.users.data = data.users;
        this.users.status = 'success';
        this.users.error = null;
      } catch (e) {
        this.users.status = 'error';
        this.users.error = e;
      }
    },

    async createUser(payload) {
      try {
        const { data } = await api.users.create(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async updateUser(payload) {
      try {
        const { data } = await api.users.update(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async updateUserInfo(payload) {
      try {
        const { data } = await api.users.updateUserInfo(payload);

        this.user = {
          ...this.user,
          ...payload,
        };

        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async deleteUser(id) {
      try {
        const { data } = await api.users.delete(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async restoreUser(id) {
      try {
        const { data } = await api.users.restore(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },

    selectUser(user) {
      this.selectedUser = user;
    },

    async changeLimit() {
      try {
        await api.users.changeLimit();

        await this.getUser();

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async addExtraLimit() {
      try {
        await api.users.addExtraLimit();

        await this.getUser();

        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },

  getters: {
    usersHashmap() {
      return this.users.data.reduce((acc, curValue) => {
        acc[curValue.id] = curValue;

        return acc;
      }, {});
    },
    groupsList() {
      return this.groups.data;
    },
    groupsHashmap() {
      return this.groups.data.reduce((acc, curValue) => {
        acc[curValue.id] = curValue;
        return acc;
      }, {});
    },
    isAuthenticated() {
      return !!this.authToken;
    },
    isAdmin() {
      return !!this.user?.admin_type;
    },
    hasPremium() {
      return this.user?.premium;
    },
    cardSubscribed() {
      return this.user?.is_cart_subscribed;
    },
    hasPartPremium() {
      return this.user?.premium === 1;
    },
    hasFullPremium() {
      return this.user?.premium === 2;
    },
    hasIndividual() {
      return this.user?.premium === 3;
    },
    isInLimits() {
      const appStore = useAppStore();

      if (!this.user) {
        return true;
      }

      return appStore.downloadsLimit - this.user.limit > 0;
    },
  },
});
