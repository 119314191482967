import client from '@/../lib/plugins/axios';

export const colors = {
  // GET COLORS
  async get() {
    return client.get('/colors');
  },

  // CREATE COLOR
  async create(payload) {
    return client.post('/colors', payload);
  },

  // UPDATE COLOR
  async update(payload) {
    return client.patch(`/colors/${payload.id}`, payload);
  },

  // DELETE COLOR
  async delete(id) {
    return client.delete(`/colors/${id}`);
  },

  // RESTORE COLOR
  async restore(id) {
    return client.post(`/colors/${id}/restore`);
  },
};
