<template>
  <div class="userDropdown" :class="{ 'is-active': isDropdownVisible }">
    <AppButton color="grey" :style="{ width: width ?? 'unset' }" :outlined="isMobile" @click.stop="toggleDropdown">
      <span class="userButtonContent" v-if="!isMobile">
        <img v-if="user.premium" src="../../assets/images/emoji-crown.png" width="20" class="icon" />
        <span class="userName">
          {{ user.name }}
        </span>
        <AppIcon icon="arrow-right" size="0.75em" right />
      </span>
      <AppIcon v-else class="userIcon" icon="user-test" :color="isSecondary ? '#fff' : '#000'" :size="22" />
    </AppButton>
    <ul class="userDropdown_list">
      <li v-if="hasPremium">
        <AppButton color="black" blank @click="toAiModel">
          <img src="../../assets/images/emoji-women.png" width="20" class="icon" />
          <span>{{ $t('app.aiModel') }}</span>
        </AppButton>
      </li>
      <li>
        <AppButton color="black" blank @click="toSettings">
          <img src="../../assets/images/emoji-settings.png" width="20" class="icon" />
          <span>{{ $t('app.settings') }}</span>
        </AppButton>
      </li>
      <li>
        <AppButton color="black" blank @click="logout">
          <AppIcon icon="logout" :size="20" />
          <span>{{ $t('app.logout') }}</span>
        </AppButton>
      </li>
    </ul>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/useUserStore';
import isMobileUtil from 'is-mobile';

export default {
  name: 'UserDropdown',
  setup() {
    return {
      userStore: useUserStore(),
      isMobile: isMobileUtil(),
    };
  },
  props: {
    isSecondary: {
      type: Boolean,
    },
    width: {
      type: String,
    },
  },
  data: () => ({
    isDropdownVisible: false,
  }),
  computed: {
    user() {
      return this.userStore.user;
    },
    isAdmin() {
      return this.userStore.isAdmin;
    },
    hasPremium() {
      return this.userStore.hasPremium;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;

      document.addEventListener(
        'click',
        () => {
          this.isDropdownVisible = false;
        },
        { once: true },
      );
    },
    toAiModel() {
      this.$router.push({
        name: 'aiModel',
      });
    },
    toSettings() {
      this.$router.push({
        name: 'settings',
      });
    },
    logout() {
      this.userStore.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.userDropdown {
  position: relative;
  z-index: 10;
}

.userButtonContent {
  display: flex;
  align-items: center;
}

.userName {
  margin: 0 12px;
}

.userIcon.icon {
  margin-right: 0;
}

.userDropdown_list {
  right: 0;
  top: 100%;
  margin-top: 5px;
  border-radius: 15px;
  width: 200px;
  padding: 0.5em 0;
  overflow: hidden;
  background: var(--color-grey);
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: 0.125s ease;
  transform: translateY(15px);
  font-weight: 600;
  line-height: 113.6%;

  .userDropdown.is-active & {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .button {
    padding: 0.5em 1em;
  }
}
</style>
