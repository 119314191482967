import axios from 'axios';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueUniversalModal from 'vue-universal-modal';
import Toast from 'vue-toastification';
import Popper from 'vue3-popper';
import App from '@/App.vue';
import router from '@/router';
import SvgIcon from '@/../lib/components/commonComponents/SvgIcon.vue';
import AppSpacer from '@/../lib/components/commonComponents/AppSpacer.vue';
import AppButton from '@/../lib/components/commonComponents/AppButton.vue';
import AppInput from '@/../lib/components/commonComponents/AppInput.vue';
import AppSelect from '@/../lib/components/commonComponents/AppSelect.vue';
import { HScroll } from '@/../lib/plugins/v-horizontal-scroll';
import i18n from '@/../lib/plugins/i18n';
import { useUserStore } from '@/stores/useUserStore';

import './globals.css';
import VueGtagPlugin from 'vue-gtag';
import { isDev } from '@/../lib/utils/guards/env';
import { vClickOutside } from '@/../lib/utils/vClickOutside';
// import '@/mock';

const token = localStorage.getItem('authToken');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const userStore = useUserStore();
    if (error?.response?.status === 401) {
      userStore.logout();
    }
    return Promise.reject(error);
  },
);

const app = createApp(App)
  .use(createPinia())

  .use(router)
  .use(i18n)
  .use(VueUniversalModal, {
    teleportTarget: '#modals',
    modalComponent: 'AppModal',
  })
  .use(Toast, {
    position: 'bottom-right',
    transition: 'Vue-Toastification__fade',
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    // timeout: false,
  })
  .component('Popper', Popper)
  .component('AppIcon', SvgIcon)
  .component('AppSpacer', AppSpacer)
  .component('AppButton', AppButton)
  .component('AppInput', AppInput)
  .component('AppSelect', AppSelect)
  .directive('hscroll', HScroll)
  .directive('clickOutside', vClickOutside);

app.use(VueGtagPlugin, {
  config: {
    id: isDev(process.env.NODE_ENV)
      ? process.env.VUE_APP_DEV_ANALYTICS
      : process.env.VUE_APP_ANALYTICS,
  },
});

app.mount('#app');
