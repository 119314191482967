import client from '@/../lib/plugins/axios';

export const banners = {
  // FIND BANNERS
  async find(query, config) {
    return client.get(`/banners?${query}`, config);
  },

  // GET BANNERS
  async get(id, config) {
    return client.get(id ? `/banners/${id}` : '/banners', config);
  },

  // CREATE BANNER
  async create(payload, config) {
    return client.post('/banners', payload, config);
  },

  // UPDATE BANNER
  async update(payload, config) {
    return client.patch(`/banners/${payload.id}`, payload, config);
  },

  // DELETE BANNER
  async delete(id, config) {
    return client.delete(`/banners/${id}`, config);
  },

  // RESTORE BANNER
  async restore(id, config) {
    return client.post(`/banners/${id}/restore`, config);
  },
};
