import client from '@/../lib/plugins/axios';
import { createQueryString } from '@/../lib/utils/createQueryString';

export const generatedBanners = {
  // GET BANNERS
  async get() {
    return client.get('/generatedBanners');
  },

  // FIND BANNERS
  async findGeneratedBanners(params) {
    const query = createQueryString(params);

    return client.get(`/findGeneratedBanners${query}`);
  },
};
