import { createI18n } from 'vue-i18n';
import messages from '../locales';

const langMapper = {
  'en-US': 'en',
  en: 'en',
  ru: 'ru',
  'ru-RU': 'ru',
};

const lang = langMapper[navigator?.language];

const i18n = createI18n({
  locale: lang || 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
