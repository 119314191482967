import client from '@/../lib/plugins/axios';

export const categories = {
  // GET CATEGORIES
  async get() {
    return client.get('/categories');
  },

  // CREATE CATEGORY
  async create(payload) {
    return client.post('/categories', payload);
  },

  // UPDATE CATEGORY
  async update(payload) {
    return client.patch(`/categories/${payload.id}`, payload);
  },

  // DELETE CATEGORY
  async delete(id) {
    return client.delete(`/categories/${id}`);
  },

  // RESTORE CATEGORY
  async restore(id) {
    return client.post(`/categories/${id}/restore`);
  },

  async getCommonCategories() {
    return client.get('/categories/main_page');
  },
};
