import { ai } from '@/../lib/api/ai';
import { auth } from '@/../lib/api/auth';
import { banners } from '@/../lib/api/banners';
import { categories } from '@/../lib/api/categories';
import { colors } from '@/../lib/api/colors';
import { generatedBanners } from '@/../lib/api/generatedBanners';
import { payment } from '@/../lib/api/payment';
import { users } from '@/../lib/api/users';

export default {
  ai,
  auth,
  banners,
  categories,
  colors,
  generatedBanners,
  payment,
  users,
};
