<template>
  <component :is="tag" v-bind="$attrs" class="button" :class="{
    [`button--${color}`]: color,
    'button--outlinedThin': outlinedThin,
    'button--outlined': outlined,
    'button--ghost': ghost,
    'button--loading': loading,
    'button--progress': progress,
    'button--spinner': spinner,
    'button--success': success,
    'button--icon': icon,
    'button--blank': blank,
    'button--fullHeight': fullHeight,
    'button--bold': bold,
    'button--disabled': disabled,
  }" :style="{
    '--progress': `${progress}%`,
  }" :disabled="loading || disabled">
    <span class="button_progress"></span>
    <span class="button_loadingTitle">{{ $t('main.loading') }}</span>
    <LoaderOval class="button_spinner" :size="blank ? '1em' : '1.5em'" />
    <div class="button_content">
      <slot />
    </div>
  </component>
</template>

<script>
import LoaderOval from '@/../lib/icons/LoaderOval.vue';

export default {
  name: 'AppButton',
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    outlinedThin: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    progress: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
    },
    spinner: {
      type: Boolean,
    },
    success: {
      type: String,
    },
    icon: {
      type: Boolean,
    },
    blank: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    ghost: {
      type: Boolean,
    },
  },
  computed: {
    tag() {
      if (this.$attrs.to) {
        return 'router-link';
      } else if (this.$attrs.href) {
        return 'a';
      }
      return 'button';
    },
  },
  components: { LoaderOval },
};
</script>

<style lang="scss" scoped>
.button {
  --button-color: var(--color-grey);
  --button-text-color: var(--color-dark);
  --button-height: 45px;

  appearance: none;
  height: var(--button-height);
  border-radius: 15px;
  padding: 0 1em;
  border: none;
  background: none;
  border: none;
  color: var(--button-text-color);
  font-family: var(--font);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  flex-shrink: 0;

  &_content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &> :deep(.icon) {
      margin-right: 0.5em;
      flex-shrink: 0;
      object-fit: contain;

      &.icon--right {
        margin-right: 0;
        margin-left: 0.5em;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    background-color: var(--button-color);
    height: 100%;
    width: 100%;
  }

  &_loadingTitle {
    position: relative;
    display: none;
  }

  &_spinner {
    position: absolute;
    display: none;
  }

  &_progress {
    position: absolute;
    background-color: var(--button-color);
    height: 100%;
    width: 100%;
    left: 0;
    width: var(--progress);
    transition: 0.075s ease;
  }

  &--loading {
    &.button--progress {
      &::before {
        opacity: 0.35;
      }

      .button_content {
        display: none;
      }

      .button_loadingTitle {
        display: block;
      }
    }

    &.button--spinner {
      .button_spinner {
        display: block;
        color: inherit;

        &~.button_content {
          opacity: 0;
          display: block;
        }
      }

      .button_loadingTitle {
        display: none;
      }
    }
  }

  &--outlined {
    background: none;
    border: 2px solid var(--button-color);
    color: var(--button-color);

    &::before {
      display: none;
    }
  }

  &--outlinedThin {
    background: none;
    border: 1px solid #ffffff23;
    color: var(--button-color);

    &::before {
      display: none;
    }
  }

  &--white {
    --button-color: var(--color-white);
    --button-text-color: var(--color-dark);
  }

  &--primary {
    --button-color: var(--color-accent);
    --button-text-color: #fff;
  }

  &--black {
    --button-color: var(--color-dark);
    --button-text-color: #fff;
  }

  &--golden {
    --button-color: #ff9f47;
    --button-text-color: #fff;
  }

  &--grey {
    --button-color: var(--color-grey);
    --button-text-color: var(--color-dark);
  }

  &--red {
    --button-color: #ffd8da;
    --button-text-color: #ff5648;
  }

  &--error {
    --button-color: var(--color-error);
    --button-text-color: #fff;
  }

  &--yellow {
    --button-color: #ffdd2d;
    --button-text-color: #25262c;
  }

  &--disabled {
    opacity: 0.3;
  }

  &--icon {
    width: var(--button-height);
    padding: 0;

    :deep(.icon) {
      margin-right: 0;
    }
  }

  &--bold {
    --button-height: 70px;
  }

  &--ghost {
    border: unset;
    
    &::before {
      background-color: inherit;
    }
  }

  &--blank {
    --button-text-color: var(--button-color);

    background: none;
    padding: 0;
    height: auto;
    width: auto;
    border-radius: 0;

    &::before {
      background: none;
    }
  }

  &:disabled {
    cursor: default;
  }

  &--fullHeight {
    height: 100%;
  }
}
</style>
