<template>
  <div class="loader" :class="{
    'loader--visible': visible,
    'loader--partVisible': partOfOpacity,
  }">
    <LoaderOval :size="50" />
  </div>
</template>

<script>
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';
import LoaderOval from '@/../lib/icons/LoaderOval.vue';

export default {
  setup() {
    return {
      appStore: useAppStore(),
      userStore: useUserStore(),
    };
  },
  computed: {
    visible() {
      return (
        this.appStore.isLoaderVisible ||
        this.userStore.userStatus.type === 'loading'
      );
    },
    partOfOpacity() {
      return this.appStore.isLoaderPartOpacity;
    },
  },
  components: { LoaderOval },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.135s ease;

  &--visible {
    visibility: visible;
    opacity: 1;
    transition: none;
  }

  &--partVisible {
    opacity: 0.9;
  }
}
</style>
