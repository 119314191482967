import client from '@/../lib/plugins/axios';

export const users = {
  // GET USERS
  async get(id) {
    return client.get(id ? `/users/${id}` : '/users');
  },

  // CREATE USER
  async create(payload) {
    return client.put('/users/create', payload);
  },

  // UPDATE USER (?)
  async update(payload) {
    return client.patch(`/users/${payload.id}`, payload);
  },

  // DELETE USER
  async delete(id) {
    return client.delete(`/users/${id}`);
  },

  // RESTORE USER
  async restore(id) {
    return client.post(`/users/${id}/restore`);
  },

  // GET USER
  async getCurrentUser() {
    return client.get(`/user`);
  },

  // INCEREMENT DOWNLOADS LIMIT
  async changeLimit() {
    return client.post('/limit');
  },

  // CHECK EMAIL
  async checkEmail(email) {
    return client.post('/checking_mail', { email });
  },

  async findUser(email) {
    return client.get('/user/search', {
      params: {
        search: email,
      },
    });
  },

  // UPDATE USER INFO
  async updateUserInfo(payload) {
    return client.post('/user_update_info', payload);
  },

  // UPDATE USER PASSWORD
  async updateUserPassword(payload) {
    return client.post('/user_update_password', payload);
  },

  // ADD EXTRA LIMIT
  async addExtraLimit() {
    return client.post('/add_limit');
  },

  async getGroups() {
    return client.get('/groups');
  },
};
