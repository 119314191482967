export default {
  common: {
    generateWithAI: 'Сгенерировать с AI',
    fieldShouldBeMoreThan: `Поле должно быть не менее {0} символов`,
    fillField: 'Заполните поле',
  },
  home: {
    writeToUs: 'Создавайте потрясающие дизайны молниеносно с помощью ИИ.',
    bannerCreation:
      'Создание баннеров для Instagram, Stories, Twitter, Medium, Telegram, LinkedIn, Medium, Reddit на основе искусственного интеллекта.',
    whiteAboutYourCompany: 'Напиши, чем занимается компания или сайт',
    chooseAnArea: 'Выбери сферу',
    generate: 'Сгенерировать',
    forFeedback: 'Для обратной связи и улучшения сервиса',
    start: 'Создать Ai модель',
  },
  app: {
    cantBeEmpty: 'Не может быть пустым',
    or: 'или',
    saveChanges: 'Сохранить изменения',
    login: 'Войти',
    signup: 'Зарегистрироваться',
    logout: 'Выйти',
    create: 'Создать',
    getPremium: 'Получить премиум',
    howItWorks: 'Как это работает',
    aboutUs: 'О нас',
    bannerInOneMinute: 'Создай баннер за минуту',
    nextBannersIn: 'Новые баннеры через:',
    gotToTilda: 'Тильда',
    allUsers: 'Все пользователи',
    subscription: 'Подписки',
    allBanners: 'Все баннеры',
    aiModel: 'Ai Модель',
    settings: 'Настройки',
    days: 'дней | день | дня',
    hours: 'часов | час | часа',
    hoursShort: 'ч',
    minutes: 'минут | минута | минут',
    seconds: 'секунд | секунда | секунд',
    banners: 'баннеров | баннер | баннера',
    en: 'Англ',
    ru: 'Рус',
    changeTariff: 'Изменить тариф',
    ok: 'Ok',
  },
  colors: {
    EE5151: 'Красный',
    '189F25': 'Зеленый',
    FFC133: 'Желтый',
    '2467EC': 'Голубой',
    brown: 'Коричневый',
    FF792E: 'Оранжевый',
    '8247FF': 'Фиолетовый',
    '25262C': 'Черный',
    white: 'Белый',
    F2F2F2: 'Серый',
  },
  main: {
    aiGenerateBanner: 'Сгенерировать баннер',
    yourOffer: 'Ваше предложение',
    helpYourBusiness: 'Помоги своему бизнесу',
    description: 'Описание',
    startYourAdsFromUs: 'Начни свою рекламу с нас',
    categoryCompany: 'Категория компании',
    cryptoAssets: 'Криптовалюта',
    brandColor: 'Цвет бренда',
    loading: 'Загрузка...',
    imgProductWebsiteApp: 'Изображение',
    loadImage: 'Загрузить',
    changeImage: 'Изменить',
    logotypeCompany: 'Логотип',
    loadLogo: 'Загрузить',
    changeLogo: 'Изменить',
    ai: 'AI',
    changeBanner: 'Изменить баннер',
  },
  newBanners: {
    newBannersAfter: '100 новых баннеров через:',
    weReplenishBannersEveryWeek:
      'Мы добавляем баннеры каждую неделю, а также новые шаблоны для всех категорий',
  },
  suggestionBanner: {
    doYouHaveWishes: 'Есть пожелания или предложения?',
    writeToTelegram: 'Связаться с нами',
    forEvery: 'За каждый крутой совет - подарим премиум доступ на месяц',
    doYouWant: 'Вы хотите настроить ИИ только для своей компании?',
    orderItIndividually: 'Заказать индивидуально',
  },
  bannerItem: {
    edit: 'Редактировать',
    delete: 'Удалить',
    used: 'Использован',
    views: 'Просмотры',
    bannerHasBeenRemoved: 'Баннер удален',
    restore: 'Восстановить',
    times: 'раз | раз | раз',
    unableToDeleteBanner: 'Не получилось удалить баннер :(',
    unableToRestoreBanner: 'Не получилось восстановить баннер :(',
  },
  bannerCopyright: {
    serviceOfReadyBanners: 'Сервис готовых баннеров',
  },
  footer: {
    aboutService: 'О сервисе',
    main: 'Главная',
    aboutUs: 'О нас',
    cases: 'Кейсы',
    howItWorks: 'Как работаем',
    price: 'Цены',
    resourses: 'Ресурсы',
    mediaKit: 'Медиа Кит',
    mailing: 'Email рассылка',
    careService: 'Служба заботы',
    aboutDevelopers: 'О разработчике',
    forDesigners: 'Для дизайнеров',
    sayHello: 'Скажи “привет”',
    createBanner: 'Создать баннер',
    madeWithLoveIn: 'Сделано с любовью в',
    termsOfUse: 'Условия использования',
    privacyPolicy: 'Политика конфиденциальности',
    aiBannersIs:
      'Ai Banners - это современный сервис для создания высоко-конверсионных баннеров',
    2022: '© 2022',
  },
  bannersList: {
    thereIsNoBanners: 'Банеров по Вашему запросу нет :(',
    addCategory: 'Добавить категорию',
    banners: 'Баннеры',
    addBanner: 'Добавить баннер',
    goToAnalytics: 'Перейти в аналитику',
    noBannersCreated: 'Не создано ни одного баннера',
  },
  userEditor: {
    numberOfDownloads: 'Количество загрузок',
    conversion: 'Конверсия',
    downloadBanner: 'Загрузить баннер',
    edit: 'Редактирование',
    back: 'Назад',
    changeText: 'Изменить текст',
    changeSize: 'Изменить размер',
  },
  usersList: {
    thereAreNoUsers: 'Нет ни одного пользователя',
    users: 'Пользователи',
    addUser: 'Добавить пользователя',
    downloadSpreadsheet: 'Скачать таблицу',
    lookingForAUser: 'Ищете пользователя?',
    count: 'кол-во',
    premium: 'премиум',
    name: 'Имя',
    email: 'Email',
    tokens: 'Токены',
    downloaded: 'Скач.',
    wastedTime: 'Времени',
    notFound: 'Не найдено',
    allUsers: 'Все пользователи',
    premiumUsers: 'Премиум пользователи',
  },
  categoryItem: {
    categoryDeleted: 'Категория удалёна',
    restore: 'Восстановить',
    unableToDeleteCategory: 'Не получилось удалить категорию :(',
    unableToRestoreCategory: 'Не получилось восстановить категорию :(',
  },
  bannerEditor: {
    colors: 'Цветовые',
    schemes: 'схемы',
    conversion: 'Конверсия',
    forWhom: 'Для кого',
    category: 'Категория',
    save: 'Сохранить',
    add: 'Добавить',
    useFor: 'подходит для',
    position: 'Позиция',
    forEverySize: 'Использовать стили для всех размеров',
    size: 'Размер',
    width: 'Ш',
    height: 'В',
    text: 'Текст',
    font: 'Шрифт',
    sizeAndLimit: 'Размер и лимит',
    limit: 'Лимит',
    fontColor: 'Цвет текста',
    svgColor: 'Цвет svg',
    colorInHex: 'hex цвет',
    image: 'Изображение',
    choose: 'Выбрать...',
    shape: 'Форма',
    rounding: 'Скругление',
    rotation: 'Вращение',
    background: 'Фон',
    backgroundWithText: 'Фон с текстом',
    copyrightVersion: 'Версия значка',
    unableToLoadBanner: 'Не получилось загрузить баннер :(',
    changesSaved: 'Изменения сохранены!',
    bannerAdded: 'Баннер добавлен!',
    unableToSave: 'Не получилось сохранить :(',
    addText: 'Добавить текст',
    addImg: 'Добавить картинку',
    addSvg: 'Добавить svg',
  },
  modalCategory: {
    change: 'Изменить',
    add: 'Добавить',
    category: 'категорию',
    whopsError: 'Упс, что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    name: 'Название',
    chooseGroup: 'Выберите группу',
    forUser: 'Для пользователя',
    categoryColor: 'Цвет категории',
    changeIcon: 'Изменить иконку...',
    chooseIcon: 'Выбрать иконку...',
    save: 'Сохранить',
    forAll: 'Для всех',
    forPremium: 'Для premium',
    nameCantBeEmpty: 'Имя не может быть пустым',
    invalidEmail: 'Указан неверный формат email',
    emailCantBeEmpty: 'Email не может быть пустым',
    groupCantBeEmpty: 'Группа не может быть пустой',
    invalidColor: 'Неверный формат цвета',
    chooseIcon2: 'Выберите иконку',
    categoryAddedSuccessfully: 'Категория успешно добавлена',
    unableToAddCategory: 'Не получилось добавить категорию',
    categoryUpdatedSuccessfully: 'Категория успешно обновлена',
    unableToUpdateCategory: 'Не получилось обновить категорию',
    willShowAsCommon:
      'Будет показываться как категория пользователю на главной станице',
    willBeShownToSpecific: 'Будет показываться конкретному пользователю',
  },
  modalColorScheme: {
    change: 'Изменить',
    add: 'Добавить',
    colorScheme: 'цветовую схему',
    smthWentWrong: 'Упс, что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    colorsUsed: 'Используемые цвета:',
    mainColor: 'Основной цвет:',
    save: 'Сохранить',
    invalidColor: 'Неверный формат цвета',
    chooseAtLeastOneColor: 'Выберите хотя бы один цветовой маркер',
    colorSchemeUpdateSuccessfully: 'Цветовые схемы успешно обновлены',
    unableToUpdateColorScheme: 'Не получилось обновить цветовые схемы',
  },
  modalUser: {
    change: 'Изменить',
    add: 'Добавить',
    user: 'пользователя',
    smthWentWrong: 'Упс, что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    name: 'Имя',
    password: 'Пароль',
    tokens: 'Токены',
    group: 'Группа',
    acceptMailing: 'Отправлять email',
    giveAdminRights: 'Дать права админа',
    save: 'Сохранить',
    webDesign: 'Веб дизайн',
    development: 'Программирование',
    smmManagement: 'SMM менеджмент',
    user2: 'Пользователи',
    premiumFreelancer: 'Премиум Freelancer',
    premiumBusiness: 'Премиум Business',
    individual: 'Individual',
    nameCantBeEmpty: 'Имя не может быть пустым',
    pasCantBeEmpty: 'Пароль не может быть пустым',
    pasShouldBeMoreThan: `Пароль должен быть не менее {0} знаков`,
    emailCantBeEmpty: 'Email не может быть пустым',
    invalidEmail: 'Указан неверный формат email',
    statusCantBeEmpty: 'Статус не может быть пустым',
    onlyLatinLettersAreAllowed: 'Разрешены только латинские буквы',
    userAddedSuccessfully: 'Пользователь успешно добавлен',
    unableToAddUser: 'Не получилось добавить пользователя',
    userUpdatedSuccessfully: 'Пользователь успешно обновлён',
    unableToUpdateUser: 'Не получилось обновить пользователя',
  },
  recaptchaCompliance: {
    siteIsProtectedBy: 'Этот сайт защищен reCAPTCHA, и применяются',
    privacyPolicy: 'политика конфиденциальности',
    and: ' и ',
    termsOfUse: 'условия использования',
    google: ' Google.',
  },
  userRowItem: {
    premiumUser: 'Премиум пользователь',
    admin: 'Администратор',
    userHasBeenDeleted: 'Пользователь удалён.',
    restore: 'Восстановить',
    unableToDeleteUser: 'Не получилось удалить пользователя :(',
    unableToRestoreUser: 'Не получилось восстановить пользователя :(',
  },
  bannerCard: {
    edit: 'Редактировать',
    availableSize: 'Доступные размеры:',
    freeDownload: 'Загрузить',
    editBanner: 'Редактировать',
  },
  appInput: {
    loading: 'Загружаем...',
    change: 'Заменить',
    youCanceledImageUpload: 'Вы отменили загрузку изображения :(',
    unableToUploadImage: 'Не получилось загрузить изображение :(',
  },
  similarBanners: {
    similarBanners: 'Похожие баннеры:',
    noSimilarBanners: 'Нет похожих баннеров',
    unableToLoadBanners: 'Не получилось загрузить баннеры :(',
  },
  userEditorSizes: {
    whatBannersSizeNeeds: 'Под какие размеры нужен баннер?',
    useFor: 'подходит для',
  },
  feedbackModal: {
    youLikedTheService:
      'Если Вам понравился сервис - расскажите о нас в социальных сетях',
    supportUs: 'Поддержите нас на product hunt',
    serviceIsCompletely: 'Наш сервис абсолютно',
    free: ' бесплатный ',
    andWeArePleased:
      'и мы рады, если многие люди используют его. Мы не просим за это плату, мы лишь хотим чтобы Вы рассказали нам как можно больше о нашем продукте, это даст нам дополнительную мотивацию двигаться дальше',
  },
  authModal: {
    forCreatingBannerYouShouldAuth: 'Для создания баннера нужно войти',
    auth: 'Авторизация',
    continueWithGoogle: 'Продолжить через Google',
    registration: 'Регистрация',
    invalidLoginOrPas:
      'Упс, неверный логин или пароль. Пожалуйста, попробуйте еще раз.',
    pleaseTryAgain: 'Упс, что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    yourEmail: 'Ваш email',
    yourPassword: 'Ваш пароль',
    signIn: 'Войти в аккаунт',
    forgotPassword: 'Забыли пароль?',
    dontHaveAccYet: 'Ещё нет аккаунта?',
    signUp: 'Зарегистрироваться',
    continue: 'Продолжить',
    loginToIt: 'Войти в него',
    allreadyHaveAnAcc: 'У вас уже есть аккаунт?',
    littleAboutYou: 'Немного о вас?',
    yourName: 'Ваше имя',
    save: 'Сохранить',
    emailAlreadyExist: 'Такой email невалиден, либо уже занят',
    wantGetAd:
      'Хотите получить “10 бесплатных источников рекламы для бизнеса”?',
    failedConfirmEmail:
      'Упс, не получилось отправить email с подверждением. Пожалуйста, попробуйте еще раз.',
    recovering: 'Восстановление',
    recoveringYourPassword: 'вашего пароля',
    emailWithInsctructionsWasSended:
      'На ваш email было отправлено письмо с инструкциями. Кликните на кнопку в письме, чтобы сбросить пароль и задать новый.',
    providedInvalidEmail: 'Указали неверный email?',
    makeAReRegistration: 'Сделать перерегистрацию',
    makeNewPas: 'Задать новый пароль',
    unableToUpdatePassword:
      'Упс, не получилось обновить пароль. Пожалуйста, попробуйте еще раз.',
    newPassword: 'Новый пароль',
    update: 'Обновить',
    yourPasswordUpdatedSuccessfully: 'Ваш пароль успешно обновлён',
    yourDroppedAndUpdatedPassword:
      'Вы сбросили и обновили пароль. Теперь вы можете войти в аккаунт с новым паролем.',
    verification: 'Верификация',
    verificationOfYourEmail: 'вашего email',
    emailWasSendedClickOnUrlInIt:
      'На ваш email было отправлено письмо с подтверждением. Кликните на кнопку в письме, чтобы использовать наш сервис.',
    didntReceiveALetter: 'Не пришло письмо?',
    checkSpamFolder: 'Проверьте спам папку - и там нет?',
    sendAgain: 'Отправить еще раз',
    tryToReregister:
      'Попробуйте зарегистрироваться повторно, указав верный email. Это важно, чтобы вы могли по надобности востановить пароль.',
    weResend: 'Мы повторно',
    theLetter: 'отправили письмо',
    stillHavntReceivedLetter: 'Всё равно не пришло письмо?',
    contactUsOnTelegram: 'Связаться с нами в Telegram',
    contactUsViaEmail: 'Связаться с нами через email',
    averageResponseTime: 'Среднее время ответа: ',
    sevenMinutes: '7 минут',
    emailConfirmation: 'Подтверждение email',
    unableToConfirmEmail:
      'Упс, не получилось подтвердить email. Пожалуйста, попробуйте еще раз.',
    emailConfirmedSuccessfully: 'Ваш email успешно подтвержден',
    youDoneThat:
      'Ты справился с этим, ты красавчик, за это мы дарим тебе бесплатный доступ в наш закрытый телеграм канал',
    joinPrivateChannel: 'Вступить в закрытый канал',
    startUsing: 'Начать пользоваться',
    weNoticedSuspiciousActivity: 'Мы заметили подозрительную активность',
    sorryEntryIsNotAllowed: 'Извините, вход не разрешен.',
    weNoticedMoreThan1Man:
      'Мы заметили, что аккаунтом пользуются больше 1 человека',
    ifItsAnErrorContactUs:
      'Если это ошибка, свяжитесь со службой заботы о клиентах:',
    unblockAcc: 'Разблокировать аккаунт',
    unblockByEmail: 'Разблокировать через email',
    unableToSignIn: 'Не получилось авторизоваться',
    unableToSignUp: 'Не получилось зарегистрироваться',
    unableToSendMailWithConfirm:
      'Не получилось отправить письмо с подтверждением',
    unableToConfirmEmailErr: 'Не получилось подтвердить email',
    unableToUpdatePasswordErr: 'Не получилось обновить пароль',
    successfullyRegistered: 'Урааааа. Вы успешно зарегистрировались',
    youAreHandsome:
      'Ты справился с этим, ты красавчик, за это мы дарим тебе бесплатный доступ в наш закрытый телеграм канал',
    joinTgChanell: 'Вступить в закрытый канал',
  },
  userEditorFields: {
    header: 'Заголовок',
    description: 'Описание',
    list: 'Пункт',
    label: 'Категория',
    buttonName: 'Название кнопки',
    link: 'Ссылка',
    logo: 'Логотип',
    image: 'Изображение',
    photo: 'Фото',
    brandColor: 'Изменения цвета',
    fillCompanyName: 'Заполните название компании',
  },
  listTagCrib: {
    availableTags: 'Доступные теги',
  },
  giftBanner: {
    ourGiftToYou: 'Наш подарок для Вас',
    acceptGift:
      'Примите от нас небольшой подарок как участник нашего сообщества  - доступ в закрытую группу нашего сообщества в Telegram',
    joinInTelegram: 'Зайти в Telegram',
  },
  paymentSuccess: {
    operationSuccess: 'Ура, операция прошла успешно',
    justKnow:
      'Просто знайте, что вы сделали потрясающую инвестицию в свой бизнес. Мы рады, что вы с нами',
  },
  paymentError: {
    smthWentWrong: 'Упс, что-то пошло не так',
    smthWentWrongDuringTheOperation:
      'Что-то пошло не так во время оплаты, попробуйте еще раз или обратитесь в нашу поддержку',
    contactUsOnTelegram: 'Связаться с нами в Telegram',
    contactUsViaEmail: 'Связаться с нами через email',
  },
  uploadingImageModal: {
    letsAddImage: 'Давайте добавим или сгенерируем фото для вашего баннера',
    uploadPhotoOfYourProduct: 'Загрузите фото вашего товара для баннера',
    pngJpgJpeg: 'PNG, JPG, JPEG (max 2mb)',
    uploadFromLibrary: 'Загрузить из библиотеки',
    aiGenerate: 'Сгенерировать с AI',
    skip: 'Пропустить',
    deleteAndUploadNew: 'Изменить изображение',
    next: 'Продолжить',
  },
  paymentMethodModal: {
    chooseMethod: 'Выберите способ как бы вы хотели оплатить',
    payWithVisaMasterCard: 'Оплатить Visa / MasterCard',
    payWithTinkoff: 'Оплатить через Тинькофф',
  },
  availableLimit: {
    available: 'Доступно',
  },
  priceCard: {
    thousandsOfBanners: 'Тысячи баннеров. Безлимитная загрузка',
    freelanceTariff: 'Получить Фриланс тариф',
    businessTariff: 'Получить Бизнес тариф',
    individualTariff: 'Получить индивидуальный тариф',
    afterSubscription:
      'После оформления премиум подписки с вами свяжеться менеджер для консультаций',
    month: 'месяц',
    serviceSubscription: 'подписка на сервис',
    uponRequest: 'По запросу',
    forFreelancers: 'Для фрилансеров',
    forBusiness: 'Для бизнеса',
    individual: 'Индивидуальный',
    fastGeneration: 'Быстрая генерация картинок для постов',
    accessToAllBanners: 'Доступ к всем баннерам и приоритетная поддержка',
    unlimitedAccess: 'Безграничное скачивание на месяц',
    extraLimit: '+50 дополнительных скачиваний на месяц',
    devSpecialForYou: 'Разработка баннеров специально под ваш бренд',
    iAgreeToRegular:
      'Я согласен на регулярное списание средств (Только 1 раз в месяц - стоимость подписки) с моей банковской карты.',
    youCanCancelItAnytime:
      'В любой момент вы можете отказаться от этого в личном кабинете - отменить подписку',
    areYouSure: 'Вы уверены?',
    youAlreadyHaveTariff:
      'У Вас уже подключен тариф. Если Вы подключите новый - старый будет удален',
  },
  limitsModal: {
    downloadsLeft: 'осталось скачиваний',
    of: 'из',
    runOutOfFreeDownloads:
      'У вас закончились бесплатные скачивания - чтобы продолжить пользоваться - купите премиум',
    getDiscount: 'Получить скидку 70%',
    takeAShortSurvey:
      'Тебе всего лишь нужно пройти небольшой опрос, заполнить анкету про наш сервис и получишь специальный промокод',
    limitRunsOut: 'Упс, закончился лимит. Но мы дарим тебе',
    discount70: 'скидку на 70%',
    premiumAccess: 'премиум доступа',
  },
  newFooter: {
    copyright: 'Copyright 2024',
    allRightReserved: 'All right reserved',
    termsOfUse: 'Условия использования',
    privatePolicy: 'Политика конфиденциальности',
    bannersGallery: 'Галерея баннеров',
    aboutUs: 'О нас',
    contactUs: 'Связаться с нами',
    termsOfUseURL: 'https://ai-banner.com/terms-use-ru',
    privatePolicyURL: 'https://ai-banner.com/private-policy-ru',
  },
  settings: {
    accountSettings: 'Настройки аккаунта',
    newPassword: 'Новый пароль',
    oldPassword: 'Старый пароль',
    repeatNewPassword: 'Повторите пароль',
    profile: 'Профиль',
    name: 'Имя',
    email: 'Email',
    emailLetters: 'Email рассылка',
    iWantToSendMe: 'Я хочу получать новости о новых продуктах и акциях',
    subscription: 'Подписка',
    areYouSureToUnsub: 'Вы уверены что хотите отменить автопродление?',
    cancelSubscription: 'Отменить автопродление',
    paswordMismatch: 'Пароли не совпадают',
    unableToUpdatePas: 'Не получилось обновить пароль',
    paswordUpdatedSuccessfully: 'Пароль успешно изменен',
    youHavntSub:
      'Автопродление подписки отключено, в конце периода ваша подписка будет аннулирована',
    subscriptionCanceledSuccess: 'Автопродление успешно отменено',
  },
  socialModal: {
    urlToSocial: 'Ссылка на социальную сеть',
    socialNetwork: 'Социальная сеть',
    socialNetworkCantBeEmpty: 'Социальная сеть не может быть пустой',
    socialCantBeEmpty: 'Ссылка не может быть пустой',
    other: 'Другое',
  },
};
