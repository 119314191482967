import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      isModalAuthVisible: false,
      isModalCategoryVisible: false,
      isModalUserVisible: false,
      isModalFeedbackVisible: false,
      isModalLimitsVisible: false,
      isModalHomeImageVisible: false,
      isModalSocialVisible: false,
      isLoaderVisible: false,
      isLoaderPartOpacity: false,
      modalAuthProps: null,
    };
  },

  actions: {
    showModalAuth(modalType, extraProps) {
      this.isModalAuthVisible = modalType;
      this.modalAuthProps = extraProps || null;
    },
    showModalCategory(payload) {
      this.isModalCategoryVisible = payload;
    },
    showModalUser(payload) {
      this.isModalUserVisible = payload;
    },
    showModalFeedback(payload) {
      this.isModalFeedbackVisible = payload;
    },
    showModalLimitsVisible(payload) {
      this.isModalLimitsVisible = payload;
    },
    showModalHomeImageVisible(payload) {
      this.isModalHomeImageVisible = payload;
    },
    showModalSocialVisible(payload) {
      this.isModalSocialVisible = payload;
    },
    toggleAppLoader(payload, partVisible = false) {
      this.isLoaderVisible = payload;
      this.isLoaderPartOpacity = partVisible;
    },
  },

  getters: {
    isAnyModalVisible() {
      return (
        this.isModalFeedbackVisible ||
        this.isModalAuthVisible ||
        this.isModalCategoryVisible ||
        this.isModalLimitsVisible ||
        this.isModalHomeImageVisible ||
        this.isModalUserVisible
      );
    },
  },
});
