<template>
  <label class="checkbox">
    <input type="checkbox" :name="name" @change="update" :checked="isChecked" />
    <CheckboxBox v-if="!hideCheckbox" :isChecked="isChecked" :view="view" :withMargin="withMargin" />
    <div class="checkbox_label" v-if="!hideValue">
      <slot />
    </div>
  </label>
</template>

<script>
import { isArray } from '@/../lib/utils/isArray';
import CheckboxBox from './CheckboxBox/CheckboxBox.vue';
export default {
  setup() {
    return {
      isArray,
    };
  },
  props: {
    name: {
      type: String,
    },
    value: {
      type: [String, Number, Boolean],
      default: true,
    },
    modelValue: {
      type: null,
    },
    isPermanentValue: {
      type: Boolean,
    },
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
    hideValue: {
      type: Boolean,
      default: false,
    },
    view: {
      default: 'square',
      type: String,
    },
    withMargin: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    update($event) {
      if (this.isPermanentValue) {
        $event.target.checked = this.value;
        return;
      }
      if ($event.target.checked) {
        this.check();
      } else {
        this.uncheck();
      }
    },
    check() {
      if (this.isArray(this.modelValue)) {
        const listValues = this.modelValue.slice(0); // Clone array
        listValues.push(this.value);
        this.$emit('update:modelValue', listValues);
        return;
      }
      this.$emit('update:modelValue', this.value);
    },
    uncheck() {
      if (this.isArray(this.modelValue)) {
        const listValues = this.modelValue.slice(0); // Clone array
        const valueIndex = listValues.indexOf(this.value);
        listValues.splice(valueIndex, 1); // Remove current value from array
        this.$emit('update:modelValue', listValues);
        return;
      }
      if (this.value === true) {
        this.$emit('update:modelValue', false);
        return;
      }
      this.$emit('update:modelValue', null);
    },
  },
  computed: {
    isChecked() {
      if (this.isPermanentValue) {
        return this.value;
      }
      return (
        (!isArray(this.modelValue) && this.modelValue) ||
        this.modelValue?.includes?.(this.value)
      );
    },
  },
  components: { CheckboxBox },
};
</script>

<style lang="scss" scoped>
.checkbox {
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    z-index: -1;
  }
}
</style>
